import moment from "moment-timezone"
import { styled } from '@stitches/react'
import compliments from './compliments'
import Info from './info'
import { use100vh } from 'react-div-100vh'

const Page = styled('div', {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& h1': {
        padding: 0,
        fontSize: '30px',
        '@media screen and (max-width: 1000px)': {
            fontSize: '25px',
        }
    }
});
const Today = styled('h2', {
    padding: 0,
    fontSize: '30px',
    '@media screen and (max-width: 1000px)': {
        fontSize: '25px',
    }
})
const Compliment = styled('p', {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    boxSizing: 'border-box',
    maxWidth: 1000,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '5vw',
    '@media screen and (max-width: 700px)': {
        fontSize: '45px',
    },
    // fontWeight: 'bold',
    padding: 30
})
const Complimenter = () => {
    const dateFormat = 'D MMMM YYYY'


    // '2024-08-26'
    const day = window.location.href.split('?d=')[1]
    const today = day ? moment(day).startOf('day') : moment().startOf('day');
    const birthday = moment('2024-08-25');
    const dayDifference = birthday.diff(today, 'days')

    const dailyCompliment = compliments[(366 - dayDifference)]

    const rotation = Math.floor(Math.random())
    const fullHeight = use100vh()
    return (<Page id="page" css={{
        background: `conic-gradient(coral, cyan, coral, cyan, coral)`,
        filter: `hue-rotate(${dayDifference * 10}deg)`,
        height: fullHeight
    }}>
        <h1>Dear Fredi...</h1>
        <Info/>
        {dayDifference >= 0 ? <Compliment>{dailyCompliment}</Compliment> : <>
            <Compliment>
                <i>Sorry, it's not your birthday anymore!</i>
            </Compliment>
            {/* <Table>
                {compliments?.map((compliment, c) => <p key={c}>{compliment}</p>)}
            </Table> */}
        </>}
        <Today>{today.format(dateFormat)}</Today>
    </Page>)
}
export default Complimenter