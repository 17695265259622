import { styled } from '@stitches/react'
import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { BsBalloonFill, BsXLg } from "react-icons/bs";
Modal.setAppElement('body')

const InfoButton = styled('button', {
    position: 'fixed',
    top: 0,
    right: 0,
    padding: 20,

    '&.close': {
        position: 'absolute'
    },
    '& svg': {
        width: 30,
        height: 30
    }
})
const Content = styled('div', {
    height: '100%',
    width: '100%',
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    background: 'white',
    // fontWeight: 'bold',
    fontSize: '18px',
    '& p ': {
        padding: 0,
        margin: 0,
        '+ p': {
            paddingTop: '1em',
        },
        textAlign: 'left'
    }
})
const Info = () => {
    const [ modalOpen, setModalOpen ] = useState(false)
    // useEffect(() => {
        // Modal.setAppElement('body');
    // }, [])
    return (<>
        <InfoButton onClick={() => setModalOpen(true)}><BsBalloonFill/></InfoButton>
        <Modal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            style={{overlay: {},content: {}}}
            contentLabel={'Info'}
            className={`DefaultModal`}
            overlayClassName={`DefaultOverlay`}
            portalClassName={`ReactModalPortal`}
            bodyOpenClassName={`modalOpen`}
            closeTimeoutMS={400}
        >
            <InfoButton className="close" onClick={() => setModalOpen(false)}>
                <BsXLg/>
            </InfoButton>
            <Content>
                <p>
                    Dear Fredi,
                </p><p>
                    Here are 365 unique compliments just for you! Something to get yourself hyped up for every day until next year.
                </p>
                <p>
                    Courtesy of SB® & GPT 4.
                </p>
                <p>
                    Happy Birthday,<br/>
                    Carla & Karlis
                </p>
            {/* <p>This website is a birthday present given to Fredi by Shadow Brand® on 25 August 2023. It gives Fredi a compliment per day, each day for a year.</p>
            <p>The 366 compliments were composed with the aid of ChatGPT 4. </p> */}
            </Content>
        </Modal>
    </>)
}
export default Info